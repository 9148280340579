<template>
  <div class="project-detail">
    <div class="project-detail-header">
      <public-header></public-header>
      <!-- 大标题 -->
      <div class="project-detail-title">
        <p>{{$t("projectInnerFangChengbanner1")}}</p>
      </div>
      <div class="scoll-icon beat" :class="showMapFlag?'beat-show':''" @click="showMap">
        <img src="../../../assets/images/project/address.png" alt="icon">
      </div>
      <div class="map-box" v-if="showMapFlag">
        <div class="map">
          <p>
            <span>{{$t("projectInnerFangChengMap1")}}</span>
            <span>{{$t("projectInnerFangChengMap2")}}</span>
          </p>
          <svg t="1620560910108" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4025" width="15" height="19"><path d="M512 0.006c-212.85 0-385.398 172.549-385.398 385.398 0 212.85 270.652 638.589 385.398 638.589 114.746 0 385.398-425.739 385.398-638.589C897.398 172.555 724.85 0.006 512 0.006z m0 559.885c-96.366 0-174.486-78.12-174.486-174.486S415.634 210.918 512 210.918c96.366 0 174.486 78.121 174.486 174.486S608.366 559.891 512 559.891z" p-id="4026" fill="#2c2c2c"></path></svg>
        </div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div class="project-detail-content">
      <div class="project-detail-content-inner center">
        <div class="project-detail-left">
          <h6>{{$t("projectInnerFangChengTitle1")}}</h6>
          <p>{{$t("projectInnerFangChengTitleDetail1")}}</p>
          <h6>{{$t("projectInnerFangChengTitle2")}}</h6>
          <p><span>
            {{$t("projectInnerFangChengTitleDetail2")}}</span>
            <span>{{$t("projectInnerFangChengTitleDetail21")}}</span></p>
          <h6>{{$t("projectInnerFangChengTitle3")}}</h6>
          <p>{{$t("projectInnerFangChengTitleDetail3")}}</p>
          <h6>{{$t("projectInnerFangChengTitle4")}}</h6>
          <p class="margin-bottom">{{$t("projectInnerFangChengTitleDetail4")}}</p>
        </div>
        <div class="project-detail-right">
          <ol>
            <li><s></s> <span>{{$t("projectInnerFangChengRightDetail1")}}</span></li>
            <li><s></s> <span>{{$t("projectInnerFangChengRightDetail2")}}</span></li>
            <li><s></s> <span>{{$t("projectInnerFangChengRightDetail3")}}</span></li>
            <li><s></s> <span>{{$t("projectInnerFangChengRightDetail4")}}</span></li>
            <li><s></s> <span>{{$t("projectInnerFangChengRightDetail5")}}</span></li>
          </ol>
          <ul>
            <li><img src="../../../assets/images/project/SDG4.png" alt="pic1"></li>
            <li><img src="../../../assets/images/project/SDG8.png" alt="pic2"></li>
            <li><img src="../../../assets/images/project/SDG13.png" alt="pic3"></li>
            <li><img src="../../../assets/images/project/SDG15.png" alt="pic4"></li>
          </ul>
          <carousel :pageSize="pageSize" :name="name"></carousel>
        </div>
      </div>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
import carousel from "../components/carousel.vue"
export default {
  name: 'forestFarmCarbonSinkProject-Jiangxi',
  components:{
    "carousel": carousel,
  },
  data(){
    return {
      name: "FangCheng",  //  图片路径名称
      pageSize: 11 ,  //  文件夹里面 多少个图片 数字多大
      showMapFlag: false
    }
  },
  methods:{
    showMap(){
      this.showMapFlag = !this.showMapFlag;
    }
  }
}
</script>
<style scoped>
@import "../../../assets/css/projectDetail.css";
.project-detail-header {
  background-image: url(../../../assets/images/project/bg-fangCheng.png);
} 
.margin-bottom {
  margin-bottom: 6px;
}
.map p {
  right: -105px;
  bottom: 110px;
}
.map svg {
  right: 198px;
  top: 249px;
}
#app.word-spacing .map p{
  width: 290px;
  left: -85px;
  bottom: 29px;
}
</style>
