<template>
  <div class="detail">
    <detail-header :logoFlag="false"></detail-header>
    <div class="detail-content center">
      <div class="detail-img">
        <img src="../../../assets/images/detail/neo.png" alt="carl">
      </div>
      <div class="detail-article">
        <h3>{{$t("teamItem2")}}</h3>
        <h4>{{$t("teamItemPost21")}}</h4>
        <h4>{{$t("teamItemGet2")}}</h4>
        <a href="https://www.linkedin.com/in/neo-lin-0605/"><img src="../../..//assets/images/detail/link.png" alt=""></a>
        <p>{{$t("teamItemDetail2")}}</p>
        <div class="button">
          <router-link :to="{path:'aboutUs'}">
            <span>{{$t("back")}}</span>
          </router-link>
        </div>
      </div>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name: "neo",
}
</script>
<style scoped>
@import "../../../assets/css/detail.css";
#app.word-spacing .detail-content {
  height: 790px;
}
</style>