<template>
  <div class="detail">
    <detail-header :logoFlag="false"></detail-header>
    <div class="detail-content center">
      <div class="detail-img">
        <img src="../../../assets/images/detail/chenChen.png" alt="carl">
      </div>
      <div class="detail-article">
        <h3>{{$t("teamItem5")}}</h3>
        <h4>{{$t("teamItemPost5")}}</h4>
        <h4>{{$t("teamItemGet5")}}</h4>
        <p>{{$t("teamItemDetail5")}}</p>
        <div class="button">
          <router-link :to="{path:'aboutUs'}">
            <span>{{$t("back")}}</span>
          </router-link>
        </div>
      </div>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name: "chenChen",
}
</script>
<style>
@import "../../../assets/css/detail.css";
</style>